import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(private apiService:ApiService) { }




  pullAllCity():Observable<any>{
    return this.apiService.getAllCity();
  }
  pullAllCitySecond():Observable<any>{
    return this.apiService.getAllCitySecond();
  }
  pullAllForPersonal():Observable<any>{
    return this.apiService.getAllCitypersonal();
  }
  pullFormID():Observable<any>{
    return this.apiService.getApipullFormID();
  }

  pullTechnologyTrach(e):Observable<any>{
    
   return this.apiService.getAllTechTrack(e);
  }
  pullcityPSecond(e):Observable<any>{
    
   return this.apiService.getcityPsecond(e);
  }

  pullbootcamp(e,getCity):Observable<any>{
   
    return this.apiService.getBootcamp(e,getCity);
  }
  pullbootcampbyID(e):Observable<any>{
  
    return this.apiService.getBootcampByID(e);
  }
  pullbootcampafterCoupen(e):Observable<any>{
  
    return this.apiService.getBootcampAfterCoupen(e);
  }
  pullCurrentCompany():Observable<any>{
   
    return this.apiService.fetchAllcompany();
  }

  sendApplicationForm(submitFormData):Observable<any>{
  
    return this.apiService.sendAppForm(submitFormData);
  }
  sendCoupenCode(coupenCodeValue):Observable<any>{
 
    return this.apiService.sendCoupenCodeApi(coupenCodeValue);
  }
  checkCnic(getCnic):Observable<any>{
    
    return this.apiService.checkCnicApi(getCnic);
  }
}
