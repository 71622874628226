 
export const environment = {
 
  // baseUrl: 'https://atsapi.techlift.thetowertech.com/ats-api/atsapi/'
  //  baseUrl: 'http://10.12.4.201:8082/atsapi/'
  // baseUrl: 'http://10.12.5.126:8082/atsapi/'
  // baseUrl: 'http://10.12.5.121:8082/atsapi/'
  baseUrl: 'http://demoucp.admissions.thetowertech.com/atsapi/'
  
   
};

 