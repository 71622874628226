import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpBackend } from "@angular/common/http";
import { environment } from '../environments/environment';
 

const ENV = environment;
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + btoa('ttl:ttl123')
    }),
  }; 
  constructor(private http:HttpClient) { }


  public getAllCity():Observable<any>{
     return this.http.post<any>(ENV.baseUrl + "city",null);
  
  }
  public getApipullFormID():Observable<any>{
     return this.http.post<any>(ENV.baseUrl + "getRegistrationFormId",null);
    
  }
  public getAllCitySecond():Observable<any>{
     return this.http.post<any>(ENV.baseUrl + "city",null);
    
  }
  public getAllCitypersonal():Observable<any>{
     return this.http.post<any>(ENV.baseUrl + "city",null);
   
  }
  public getAllTechTrack(e):Observable<any>{
  
    return this.http.post<any>(ENV.baseUrl + "tt/"+ e,null);
  }
  public getcityPsecond(e):Observable<any>{
   
    return this.http.post<any>(ENV.baseUrl + "tt/"+ e,null);
  }
  public getBootcamp(e,getCity):Observable<any>{
  
    return this.http.post<any>(ENV.baseUrl + "bootcamp/"+ e + '/' + getCity,null);
  }
  public getBootcampByID(e):Observable<any>{
  
  
    return this.http.post<any>(ENV.baseUrl + "bootcamp/id/"+ e,null);
  }
  public getBootcampAfterCoupen(e):Observable<any>{
  
  
    return this.http.post<any>(ENV.baseUrl + "bootcamp/id/"+ e,null);
  }
 public fetchAllcompany():Observable<any>{
  
    return this.http.post<any>(ENV.baseUrl + "company",null);

  }
  public sendAppForm(submitFormData):Observable<any>{
   
    return this.http.post<any>(ENV.baseUrl + "saveadmissionform",submitFormData);

  }
  public sendCoupenCodeApi(coupenCodeValue):Observable<any>{
 
    return this.http.post<any>(ENV.baseUrl + "getbootcampbycode/"+ coupenCodeValue,null);

  }
  public checkCnicApi(getCnic):Observable<any>{
   
    return this.http.post<any>(ENV.baseUrl + "cnicDuplicationCheck/"+ getCnic,null);

  }
}
