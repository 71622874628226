export class cityId {
    cityId: number;
   
}
export class companyId {
    companyId: number;
   
}
export class bootcampId {
    bootcampId: number;
   
}
export class bootcampId2 {
    bootcampId: number;
   
}
export class registrationForm {
  registranFormId:number;
}

export class AcdParam {
  degreeTxt: string = '';
  degreeCgpa: string = '';
  passingYear: string = '';
  degreeSpecialization: string = '';
  institute: string = '';
  
 
 

  
}

export class FormSubmit {

  registranFormId:number;
    refNbr: number;
    candidateName: string;
    email: string;
    gender: string;
    dob: Date;
    cnic: string;
    mobileNbr: string;
    mailingAddress: string;
    lastQualificationTitle: string;
    lastQualificationInstitute: string;
    lastQualificationYear: string;
    employerName: string;
    employeeDesignation: string;
    jobStartDate: string;
    jobEndDate: string;
    termSConditionTxt: string;
    bootcampCode:number;
    cityId:  cityId = {
        ... new cityId()
      };
    formSubmitDate: Date;
    jvEmloyeeInd: string;
    ownLaptopInd: string;
    graduateAfter2017: number;
    employmentStartDate: Date;
    employmentEndDate: Date;
    companyId:  companyId = {
        ... new companyId()
      };
    bootcampId:  bootcampId = {
        ... new bootcampId()
      };
    bootcampId2:  bootcampId2 = {
        ... new bootcampId2()
      };
    statusInd: string;
    academicDetail: AcdParam[] = [{
      ... new AcdParam()
    }];
     
}



 

