export class AcdParam {
  degree: string;
  cGPA: string;
  passingYear: string;
  specialization: string;
  instituate: string;
}

export class AllInfo {
  city: string;
  technologyTrack: string;
  bootcam: string;
  cityPsecond: string;
  technologyTrackPsecond: string;
  bootcamPsecond: string;
  imGraduated: Boolean;
  fullName: string;
  email: string;
  dOB: Date;
  mobile: Number;
  cnic: Number;
  address: string;
  haveLaptop: Boolean;
  yourCity: string;
  employerName: string;
  designation: string;
  jobStartDate: Date;
  jobEndDate: Date;
  currentCompany: string;
  currectInfo: Boolean;
  acdParams: AcdParam[] = [{
    ... new AcdParam()
  }];
}
