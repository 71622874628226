import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NgForm, NgModel } from "@angular/forms";
import { Router } from "@angular/router";
import { AllInfo } from "../_interfaces/allInfo";
import { BootCamp } from "../_interfaces/bootcamp";
import { City } from "../_interfaces/city";
import { Company } from "../_interfaces/company";
import {
  registrationForm,
  FormSubmit,
  companyId,
} from "../_interfaces/formSumit";
import { TechTrack } from "../_interfaces/techTrack";
import { UtilityService } from "../_services/utility.service";
import { AcdParam } from "../_interfaces/formSumit";
import { bootcampCodeCoupen } from "../_interfaces/bootcampCodeCoupen";
@Component({
  selector: "app-std-application-form",
  templateUrl: "./std-application-form.component.html",
  styleUrls: ["./std-application-form.component.css"],
})
export class StdApplicationFormComponent implements OnInit {
  city: City;
  cityPSecond: City;
  cityPdata: City;
  currentCompanylist: Company;
  techTrack: TechTrack;
  techTrackPSecond: TechTrack;
  bootcampFirstList: BootCamp;
  bootcampFirstListbyid: BootCamp;
  bootcampSecondListbyid: BootCamp;
  bootcampSecondst: BootCamp;
  bootcampSecond = new BootCamp();
  allInfo = new AllInfo();
  getid = new AcdParam();
  submitFormData = new FormSubmit();
  hideComapny = true;
  getCnic: string;
  cnicReg:boolean;
  coupenFlag = false;
  cnicExit = false;
  otherReason: string;
  cityID: number;
  cityIDSecond: number;
  @ViewChild("acd") acd: ElementRef;
  hTML: string[] = [];
  arr: any;
  cadidateForm = false;

  techTrackone = false;
  prioritySecondCity = false;
  bootcampOne = false;
  bootcampTwo = false;
  assignFormNumber: number;
  bootcampCodeCoupen = new bootcampCodeCoupen();
  coupenCodeValue: number;
  bootcampHide = true;
  keycoutn: number;
  @ViewChild("wihtoutBookCmap") private wihtoutBookCmap: ElementRef;
  @ViewChild("withBootcamp") private withBootcamp: ElementRef;
  @ViewChild("cnic") private cnic: ElementRef;
  @ViewChild("applicationForm") private applicationForms: NgForm;
  bootcapTable1 = false;
  bootcapTable2 = false;
  bootcapTable3 = false;
  loaderFlag: boolean = false;
  valuesKey = '';

  ngDropdown = 1;

  cnicDashes :boolean = false;
bootcamMatchOne :string;
bootcamMatchTwo :string;
errorTableActive:boolean = false;
  @ViewChild("selectcompany") private selectcompany: NgModel;

  constructor(
    private _utilityService: UtilityService,
    private _router: Router
  ) {}
  ngOnInit() {
    this.getcities();
    this.getCurrentlyCompany();
    this.getcitiesProSecond();
    this.getcitiesForPersonalInfo();
    this.getFormID();
    
  }

  getcities() {
    this.errorTableActive = false;
    this._utilityService.pullAllCity().subscribe((res) => {
      if (res) {
        this.city = res;
      }
    });
  }

  getcitiesProSecond() {
    this.errorTableActive = false;
    this._utilityService.pullAllCitySecond().subscribe((res) => {
      if (res) {
        this.cityPSecond = res;
      }
    });
  }
  getcitiesForPersonalInfo() {
    this._utilityService.pullAllForPersonal().subscribe((res) => {
      if (res) {
        this.cityPdata = res;
      }
    });
  }

  getCurrentlyCompany() {
    this._utilityService.pullCurrentCompany().subscribe((res) => {
      if (res) {
        this.currentCompanylist = res;
      }
    });
  }

  addAcdemic(i) {
    this.submitFormData.academicDetail.push(new AcdParam());
  }
  removeItems(i) {
    if (i > 0) {
      this.submitFormData.academicDetail.splice(i, 1);
    }
  }

  changeTrackTech(e) {
    this.bootcampFirstList = null;
    this.techTrack = null;

    this.cityID = e;

    this._utilityService.pullTechnologyTrach(e).subscribe((res) => {
      if (res.length > 0) {
        this.techTrack = res;
        this.techTrackone = true;
        this.allInfo.technologyTrack = null;
      } else {
        this.techTrack = null;
        this.bootcampFirstList = null;
        this.submitFormData.bootcampId.bootcampId = null;

        // this.techTrackone = false;

        // this.bootcampOne = false;
      }
    });
  }
  cityPSec(e) {
    this.bootcampSecondst = null;

    this.cityIDSecond = e;
    this._utilityService.pullcityPSecond(e).subscribe((res) => {
      if (res.length > 0) {
        this.techTrackPSecond = res;
        this.prioritySecondCity = true;
        this.allInfo.technologyTrackPsecond = null;
      } else {
        this.techTrackPSecond = null;
        this.bootcampSecondst = null;
        this.submitFormData.bootcampId2.bootcampId = null;
        // this.prioritySecondCity = false;
        // this.bootcampTwo = false;
      }
    });
  }

  changeTrackBootcamp(e) {
    var getCity = this.cityID;
    this._utilityService.pullbootcamp(e, getCity).subscribe((res) => {
      if (res) {
        this.bootcampFirstList = res;
        this.bootcampOne = true;
      }
    });
  }

  changePrioritySecondTrack(e) {
    this.errorTableActive = false;
    var getCity = this.cityIDSecond;
    this._utilityService.pullbootcamp(e, getCity).subscribe((res) => {
      if (res) {
        this.bootcampSecondst = res;
        this.bootcampTwo = true;
      }
    });
  }

  getFormID() {
    this._utilityService.pullFormID().subscribe((res) => {
      if (res) {
        this.assignFormNumber = res;
      }
    });
  }

  coupenCode(coupen: NgForm) {
    this.errorTableActive = false;
    this.coupenCodeValue = coupen.value.bootcampcode;
    this._utilityService
      .sendCoupenCode(this.coupenCodeValue)
      .subscribe((res) => {
        if (res.bootcampId != 0) {
          this.coupenFlag = false;
          this.bootcampSecond = res;

          this.submitFormData.bootcampId.bootcampId =
            this.bootcampSecond.bootcampId;
             

          
        
           
          var e = this.submitFormData.bootcampId.bootcampId;
          this.bootcamMatchOne =e.toString();
          if(this.bootcamMatchOne === this.bootcamMatchTwo){
            this.errorTableActive =true;
          }
          this.bootcapTable3 = false;
          this.bootcapTable2 = false;
          this.bootcampHide = false;
          this.wihtoutBookCmap.nativeElement.remove();
          // this.applicationForms.controls['candidateName'].clearValidators();
          // this.applicationForms.controls['candidateName'].updateValueAndValidity();
          // this.applicationForms.controls['fistCity'].clearValidators();
          // this.applicationForms.controls['fistCity'].updateValueAndValidity();
          // this.applicationForms.controls['fistCityselect'].clearValidators();
          // this.applicationForms.controls['fistCityselect'].updateValueAndValidity();
          // this.applicationForms.controls['selectTechTrack'].clearValidators();
          // this.applicationForms.controls['selectTechTrack'].updateValueAndValidity();
          // this.applicationForms.controls['selectTechTracksecond'].clearValidators();
          // this.applicationForms.controls['selectTechTracksecond'].updateValueAndValidity();
          // this.applicationForms.controls['bootcampId'].clearValidators();
          // this.applicationForms.controls['bootcampId'].updateValueAndValidity();
          // this.applicationForms.controls['bootcampId2'].clearValidators();
          // this.applicationForms.controls['bootcampId2'].updateValueAndValidity();

          this._utilityService.pullbootcampafterCoupen(e).subscribe((res) => {
            if (res) {
              this.bootcampFirstList = res;
              this.bootcampOne = true;
              this.bootcapTable1 = true;
              this.bootcapTable2 = false;
              this.bootcapTable3 = false;
            }
          });
        } else {
          this.coupenFlag = true;
        }
      });
  }

  onSubmit(applicationForm: NgForm) {
    if (applicationForm.invalid) {
      window.scroll(0, 0);
      return;
    }
    if (this.cnicDashes === true) {
      window.scroll(0, 0);
      return;
    }
    if (applicationForm.valid) {
      this.loaderFlag = true;

      this.cnicExit = false;

      this.getCnic = applicationForm.value.cnic;

      this._utilityService.checkCnic(this.getCnic).subscribe(
        (data) => {
          if (data.flag == "false") {
            this.loaderFlag = false;
            window.scroll(0, 350);
            this.cnicExit = true;
            return;
          } else {
            this.cnicExit = false;
            this.submitFormData.candidateName =
              this.submitFormData.candidateName.trim();
            if (this.submitFormData.employerName) {
              this.submitFormData.employerName =
                this.submitFormData.employerName.trim();
            }
            if (this.submitFormData.employeeDesignation) {
              this.submitFormData.employeeDesignation =
                this.submitFormData.employeeDesignation.trim();
            }
            this.submitFormData.bootcampCode =
            this.bootcampCodeCoupen.bootcampCode;
            this.submitFormData.statusInd = "S";
            this.submitFormData.registranFormId = this.assignFormNumber;
            this.submitFormData.graduateAfter2017 = 1;
            this.submitFormData.ownLaptopInd = "Y";
            this.submitFormData.termSConditionTxt = "Y";

            this._utilityService
              .sendApplicationForm(this.submitFormData)
              .subscribe(
                (res) => {
                  if (res.status === 900) {
                    this.loaderFlag = false;
                    this._router.navigate(["/thankYou"]);
                  } else if (res.status === 903) {
                    this.loaderFlag = false;
                    window.scroll(0, 0);
                    this.cadidateForm = true;
                    this.otherReason ="Some thing went wrong, please try later or contact us at info@techlift.pk." + res.message;
                  } else if (res.status === 905) {
                    this.loaderFlag = false;
                    window.scroll(0, 0);
                    this.cadidateForm = true;
                    this.otherReason = res.message;
                  } else if (res.status === 902) {
                    this.loaderFlag = false;
                    window.scroll(0, 0);
                    this.cadidateForm = true;
                    this.otherReason = res.message;
                  }
                },
                (error) => {
                  this.loaderFlag = false;
                  window.scroll(0, 0);
                  this.cadidateForm = true;
                  this.otherReason =
                    "Some thing went wrong, please try later or contact us at info@techlift.pk.";
                }
              );
          }
        },
        (error) => {
          this.loaderFlag = false;
          window.scroll(0, 0);
          this.cadidateForm = true;
          this.otherReason =
            "Some thing went wrong, please try later or contact us at info@techlift.pk.";
        }
      );
    }
  }

  onKeyDownEvent(event) {
    var getCnic = event.target.value;

    this.keycoutn = this.cnic.nativeElement.value.length;
    if (this.keycoutn === 13) {
    }
  }

  getAllBootcamps(e) {
    this.bootcamMatchOne = e;
   
    if(this.bootcamMatchOne === this.bootcamMatchTwo){
      this.errorTableActive =true;
    }
    this._utilityService.pullbootcampafterCoupen(e).subscribe((res) => {
      if (res) {
        this.bootcampFirstList = res;
        this.bootcampOne = true;
        this.bootcapTable1 = true;
        this.bootcapTable2 = false;
        this.bootcapTable3 = false;
      }
    });
  }
  getAllBootcampsFirst(e) {
    this.bootcamMatchOne = e;
    if(this.bootcamMatchOne === this.bootcamMatchTwo){
      this.errorTableActive =true;
    }

  
    this._utilityService.pullbootcampbyID(e).subscribe((res) => {
      if (res) {
        this.bootcampFirstListbyid = res;
        this.bootcampOne = true;
        this.bootcapTable2 = true;
      }
    });
  }
  getAllBootcampsSecond(e) {
    this.errorTableActive = false;
     
   
    this.bootcamMatchTwo = e;
 
    if(this.bootcamMatchTwo === this.bootcamMatchOne){
      
      this.errorTableActive = true;
    }
   
    this._utilityService.pullbootcampbyID(e).subscribe((res) => {
      if (res) {
        this.bootcampSecondListbyid = res;
        this.bootcampOne = true;
        this.bootcapTable3 = true;
      }
    });
  }

  companyPre(e) {
    this.getCurrentlyCompany();
    this.hideComapny = false;
  }
  companyPrehide() {
    this.submitFormData.employerName = "";
    this.submitFormData.employeeDesignation = "";
    this.submitFormData.jobStartDate = "";
    this.submitFormData.jobEndDate = "";
    this.submitFormData.companyId = {
      ...new companyId(),
    };

    this.getCurrentlyCompany();
    this.hideComapny = true;
  }

  refreshPage() {
    window.location.reload();
  }

  checkCnic(event: any){
  
    this.valuesKey = event.target.value;
     const rExp  = new RegExp("^[0-9]{5}-[0-9]{7}-[0-9]{1}$");
   
   if ( rExp.test(  this.valuesKey) === false) {
       this.cnicDashes = true
   }else{
    this.cnicDashes = false
   }

    
  }
}
