import { Time, WeekDay } from "@angular/common";

export class Company {
  companyId: number;
  companyCode: string;
  companyName: string;
  activeInd: string;
}
export class City {
  cityId: number;
  cityAbbrev: string;
  cityName: string;
}

export class StudyCenter {
  studyCentreId: number;
  studyCentreName: string;
  studyCentreAddress: string;
  studyCentreLocation: string;
  city: City;
}

export class course {
  courseId: number;
  courseCode: string;
  courseTitle: string;
  courseFee: number;
  courseFeeType: string;
  courseDuration: string;
  admissionStartDate: Date;
  admissionEndDate: Date;
}

export class BootCamp {
  bootcampId: number;
  company: Company;
  studyCentre: StudyCenter;
  course: course;
  trainingManager: string;
  timeSlot: string;
  weekDays: WeekDay;
  capacity: string;
  activeInd: string;
  bootcampStartDte:Date;
}
